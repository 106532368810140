export const normal = [
  '#6b6ecf',
  '#8ca252',
  '#e7ba52',
  '#d6616b',
  '#ce6dbd',
  '#aaaaaa',
  '#393b89',
  '#9c9ede',
  '#637939',
  '#5254a3',
  '#ad494a',
  '#e7969c',
  '#b5cf6b',
  '#cedb9c',
  '#8c6d31',
  '#e7cb94',
  '#843c39',
  '#7b4173',
  '#a55194',
  '#de9ed6',
  '#222222',
  '#444444',
  '#bd9e39',
  '#666666',
  '#888888',
  '#cccccc',
];

export const distinct = [
  '#e6194b',
  '#3cb44b',
  '#4363d8',
  '#f58231',
  '#ffe119',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#ffffff',
  '#000000',
];

export const colorLists = {
  normal,
  distinct,
};

export default normal;
