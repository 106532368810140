import Form, {Dropdown, Group, Input, Radio} from './Form';

const FormComponent = {
  Input,
  Dropdown,
  Radio,
  Group,
  Form,
};

export default FormComponent;
